import React from "react"
import I18n from "i18n-js";

const CARPET_COLOR = '#ccccff';

class BoothShapePreview extends React.Component {
  constructor(props) {
    super(props);

    this.cornerPattern = this.createFillPattern(8);
    this.fullPattern = this.createFillPattern(64);
    this.carpetPattern = this.createFillPattern(32, CARPET_COLOR);
  }

  createFillPattern(distance, color='black') {
    const p = document.createElement("canvas")
    p.width=distance;
    p.height=distance;

    const pctx = p.getContext('2d');
    pctx.strokeStyle = color;
    pctx.lineWidth = 1;
    pctx.beginPath();
    pctx.moveTo(0,distance);
    pctx.lineTo(distance,0);
    pctx.stroke();

    return p;
  }

  componentDidMount() {
    window.addEventListener('resize', this.redraw);

    this.redrawOnValidSize();
  }

  redrawOnValidSize() {
    const canvas = this.refs.canvas;
    const container = this.refs.container;
    if (!canvas || !container || container.offsetWidth === 0) {
      this._timeout = setTimeout(() => {
        this._timeout = null;
        this.redrawOnValidSize();
      }, 100);
      return;
    }

    this.redraw();
  }

  componentWillUnmount() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    window.removeEventListener('resize', this.redraw);
  }

  componentDidUpdate() {
    this.redraw();
  }

  redraw=() => {
    const canvas = this.refs.canvas;
    const container = this.refs.container;
    if (!canvas || !container) {
      return;
    }

    const width = container.offsetWidth;
    const height = 400;
    canvas.width = width;
    canvas.height = height;

    const c = canvas.getContext('2d');
    c.fillStyle = 'white';
    c.fillRect(0, 0, width, height);
    
    const cx = width / 2;
    const cy = height / 2;
    const scaleY = (height - 100) / (this.props.height);
    const scaleX = (width - 100) / (this.props.width);
    const scale = Math.min(scaleX, scaleY) * 0.9;

    const left = cx - this.props.width / 2 * scale;
    const right = cx + this.props.width / 2 * scale;
    const top = cy - this.props.height / 2 * scale;
    const bottom = cy + this.props.height / 2 * scale;
    const boothWidth = right - left + 1;
    const boothHeight = bottom - top + 1;
    const fontSize = Math.max(12, Math.round(scale * 0.5));

    c.font = `${fontSize}px Sans`
    c.fillStyle = 'black';
    c.textAlign = 'center';
    const rows = I18n.t('unconfirmed_booths.form.your_booth').split(/ /g, 2);
    c.fillText(rows[0], cx, cy-fontSize/2, right-left);
    c.fillText(rows[1] || '', cx, cy+fontSize/2, right-left);

    c.font = `16px Sans`
    const widthText = this.props.width + 'm';
    const textWidth = c.measureText(widthText).width + 10;
    

    if (this.props.position < 1) {
      this.drawForeignBooth(c, left - 10 - boothWidth, bottom + 10, boothWidth, boothHeight);
      this.drawForeignBooth(c, left, bottom + 10, boothWidth, boothHeight);
    }
    if (this.props.position < 2) {
      this.drawForeignBooth(c, left - 10 - boothWidth, top, boothWidth, boothHeight);
      this.drawForeignBooth(c, left - 10 - boothWidth, top - 10 - boothHeight, boothWidth, boothHeight);
    }
    if (this.props.position < 4) {
      this.drawForeignBooth(c, left, top - 10 - boothHeight, boothWidth, boothHeight);
    }

    if (this.props.option === 1) {
      c.strokeStyle = CARPET_COLOR;
      c.fillStyle = c.createPattern(this.carpetPattern, 'repeat');
      c.strokeRect(right + 60, -1, 2 * scale, height + 20);
      c.fillRect(right + 60, -1, 2 * scale, height + 20);
    }
    
    c.strokeStyle = 'black';
    c.beginPath();
    c.moveTo(left, bottom);
    c.lineTo(left, top);
    c.lineTo(right, top);
    c.lineTo(right, bottom);
    c.lineTo(left, bottom);
    c.stroke();

    c.strokeStyle = 'red';
    c.beginPath();
    // right lines
    c.moveTo(right, top)
    c.lineTo(right+50, top);
    c.moveTo(right, bottom)
    c.lineTo(right+50, bottom);

    // right arrows
    c.moveTo(right+30, top+10);
    c.lineTo(right+35, top);
    c.lineTo(right+40, top+10);
    c.moveTo(right+30, bottom-10);
    c.lineTo(right+35, bottom);
    c.lineTo(right+40, bottom-10);
    c.moveTo(right+35, top);
    c.lineTo(right+35, bottom);

    // bottom lines
    c.moveTo(left, bottom);
    c.lineTo(left, bottom+50);
    c.moveTo(right, bottom);
    c.lineTo(right, bottom+50);

    // bottom arrows
    if (textWidth < (right - left - 30)) {
      c.moveTo(left+10, bottom+30);
      c.lineTo(left, bottom+35);
      c.lineTo(left+10, bottom+40);
      c.moveTo(right-10, bottom+30);
      c.lineTo(right, bottom+35);
      c.lineTo(right-10, bottom+40);
      c.moveTo(left, bottom+35);
      c.lineTo(right, bottom+35);
    } else {
      c.moveTo(left-10, bottom+30);
      c.lineTo(left, bottom+35);
      c.lineTo(left-10, bottom+40);
      c.moveTo(right+10, bottom+30);
      c.lineTo(right, bottom+35);
      c.lineTo(right+10, bottom+40);
      c.moveTo(left, bottom+35);
      c.lineTo(left-20, bottom+35);
      c.moveTo(right, bottom+35);
      c.lineTo(right+20, bottom+35);
    }
    c.stroke();

    // size text
    c.fillStyle = 'white';
    if (textWidth < (right - left - 30)) {
      c.fillRect(cx - textWidth/2, bottom+34, textWidth, 20);
    }
    c.fillRect(right+1, cy-8, 50, 24);

    c.fillStyle = 'red';
    c.fillText(this.props.height + 'm', right+35, cy+8);
    c.fillText(widthText, cx, bottom + 35 + 8);
  }

  drawForeignBooth(c, left, top, width, height) {
    c.globalAlpha = 0.2;
    c.strokeStyle = 'black';
    c.beginPath();
    c.moveTo(left, top);
    c.lineTo(left+width, top);
    c.lineTo(left+width, top+height);
    c.lineTo(left, top+height);
    c.lineTo(left, top);
    c.stroke();
    c.fillStyle = c.createPattern(this.fullPattern, 'repeat');
    c.fillRect(left, top, width, height);
    c.fillStyle = c.createPattern(this.cornerPattern, 'repeat');
    c.fillRect(left, top, 10, height);
    c.fillRect(left, top, width, 10);
    c.fillRect(left+width-10, top, 10, height);
    c.fillRect(left, top+height-10, width, 10);
    c.globalAlpha = 1;
  }

  render() {
    return (
      <div className="boothshapepreview" ref="container">
        <canvas ref="canvas" />
      </div>
    );
  }
}

export default BoothShapePreview
