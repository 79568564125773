import React from "react";
import I18n from "i18n-js";

export default class FormCustomTagEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newTagText: ""
    }
  }
  
  addTag(text) {
    const match = text.match(/^\s*([\p{L}\d\-]+)\s*$/u);
    if (!match) { return; }

    text = text.replace(/^-+/gu, '');
    text = text.replace(/-+$/gu, '');

    if (this.props.tags.indexOf(text) >= 0) { return; }
    this.props.onTagAdded(text);
  }

  onBlur(text) {
    const match = text.match(/^\s*([^\s]+)(\s+([^\s].*)?)?$/)
    if (!match) { return; }

    this.addTag(match[1]);
    this.setState({newTagText: ''});
  }

  cleanUpText(text) {
    text = text.replace(/ /gu, '-');
    text = text.replace(/-+/gu, '-');
    text = text.replace(/[^\p{L}\d\-\s]+/gu, '');
    return text;
  }

  updateNewTag(text) {
    const parts = text.split(/\s*[,;]\s*/g);
    const lastPart = parts.pop();

    for (const part of parts) {
      const partText = this.cleanUpText(part);
      if (partText.length) {
        this.addTag(partText);
      }
    }

    this.setState({newTagText: this.cleanUpText(lastPart)});
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      this.onBlur(e.target.value);
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render() {
    return (      
      <div className={`form-group ${this.props.isValid===false?'field_with_errors':''}`}>
        <label className="form-label"><strong>{this.props.title}</strong> {this.props.required ? <span className="required">*</span> : null}</label>

        <div>
          <p>{I18n.t("banners.form.custom_tag_intro")}</p>
          <p>{I18n.t("banners.form.custom_tag_note")}</p>
          <p>{I18n.t("banners.form.custom_tag_example")}</p>
        </div>

        <div className="ctag-container">
          {this.props.tags.map((t) =>
            <span className="ctag-tag" key={t}>
              {t}
              <button onClick={()=>this.props.onTagRemoved(t)}>X</button>
            </span>
          )}
          <input
            type="text"
            placeholder="name-without-spaces"
            value={this.state.newTagText}
            onKeyDown={(e) => this.onKeyDown(e)}
            className="form-input"
            disabled={this.props.disabled || false}
            onChange={(e) => this.updateNewTag(e.target.value)}
            onBlur={(e) => this.onBlur(e.target.value)}
          />
        </div>
      </div>
    )
  }
}