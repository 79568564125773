import React from "react"
import UiImageCropComponent from "../ui/UiImageCropComponent";

export default class FormCropDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  cancel=(e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.props.onCancel();
  }

  commit=(e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.props.onCommit(this.crop);
  }

  setNewCrop=(crop) => {
    this.crop = crop;
  }

  render() {
    return (
      <div className="modal active ui-image-crop-modal">
        <a href="#close" className="modal-overlay" onClick={(e) => this.cancel(e)}></a>
        <div className="modal-container ui-image-crop-body">
          <div className="modal-header">
            <a href="#close" className="btn btn-clear float-right" onClick={(e) => this.cancel(e)}></a>
            <div className="modal-title h5">Bild zuschneiden</div>
          </div>
          <div className="modal-body">
            <UiImageCropComponent
              image={this.props.image}
              minAspect={this.props.minAspect}
              maxAspect={this.props.maxAspect}
              onCrop={this.setNewCrop}
            />
          </div>
          <div className="modal-footer">
            <button onClick={this.commit} className="btn btn-primary">Speichern</button>
            <button onClick={this.cancel} className="btn">Abbrechen</button>
          </div>
        </div>
      </div>
    )
  }
}