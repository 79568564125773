import React from "react";
import {StaticUrlField} from '../upload/UrlField';

export default class FormUrlListField extends React.Component {
  render() {
    return (
      <div className="form-group">
        <label className="form-label"><strong>{this.props.title}</strong></label>
        <StaticUrlField
          onChangeIndex={(k, v) => {
            const copy = this.props.value.slice()
            if (v.trim() === '') {
              copy.splice(k, 1);
            } else {
              copy[k] = v;
            }
            this.props.onChange(copy);
          }}
          links={this.props.value.concat([''])}
        />
      </div>
    )
  }
}