import React from "react";
import I18n from "i18n-js";

export default class FormTagSelector extends React.Component {
  toggle=(id) => {
    const value = this.props.value.slice();
    const index = value.indexOf(id);
    if (index === -1) {
      value.push(id);
    } else {
      value.splice(index, 1);
    }
    console.log(value);
    this.props.onChange(value);
  }

  render() {
    return (
      <div>
        <h5>{I18n.t('unconfirmed_booths.form.your_tags')}</h5>
    
        {this.props.tags.map((tg) => 
          <React.Fragment key={tg.id}>
            <p className="taglistheader"><strong>{tg.name}</strong></p>
            <ul className="taglist"> 
              {tg.tags.map((t) => 
                <li key={t.id}><label>
                  <input type="checkbox" checked={this.props.value.indexOf(t.id)>=0} onChange={(e) => this.toggle(t.id)} />
                  &nbsp;
                  {t.name}
                </label></li>
              )}
            </ul>
          </React.Fragment>
        )}
      </div>
    )
  }
}