import React from "react"
import PropTypes from "prop-types"
import I18n from "i18n-js";
import BoothList from "../booth/BoothList";

class AdditionalServicesIndex extends React.Component {
  render () {
    return (
      <div>
        <BoothList link={this.props.booking_path} />
      </div>
    );
  }
}

export default AdditionalServicesIndex
