import React from "react";
import I18n from "i18n-js";

export default class FormTextField extends React.Component {
  render() {
    return (
      <div className={`form-group ${this.props.isValid===false?'field_with_errors':''}`}>
        <label className="form-label"><strong>{this.props.title}</strong> {this.props.required ? <span className="required">*</span> : null}</label>
        <input
          className="form-input"
          type="text"
          value={this.props.value}
          onChange={(e) => this.props.onChange(e.target.value)}
        />
        {!this.props.isValid ? <span className="form-input-hint is-danger">{I18n.t('common.forms.missing_value_in_field')}</span> : null}
      </div>
    )
  }
}