import React from "react"
import FormTextField from "../form/FormTextField";
import FormTextareaField from "../form/FormTextareaField";
import FormUrlListField from "../form/FormUrlListField";
import FormCustomTagEditor from "../form/FormCustomTagEditor";
import FormImageField from "../form/FormImageField";
import I18n from "i18n-js";
import Ajax from "../common/Ajax";
import LoadingIndicator from "../common/LoadingIndicator";
import FormTagSelector from "../form/FormTagSelector";

class BannerEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    }
  }

  componentDidMount() {
    this.reloadFromServer();
  }

  async reloadFromServer() {
    try {
      const data = await (await fetch(`/api/banners/${this.props.bannerId}?hash=${this.props.hash}`)).json();

      this.setState({
        loading: false,
        error: false,
        ...data
      })
    } catch(err) {
      console.error(err);
      this.setState({
        loading: false,
        error: true,
      })
    }
  }

  async submit() {
    if (this.state.name.trim() === '') {
      alert(I18n.t("banners.form.missing_name"));
      return;
    }
    if (this.state.description.trim() === '') {
      alert(I18n.t("banners.form.missing_description"));
      return;
    }

    this.setState({loading: true, error: false});

    try {
      let imageResult, productImageResult;
      if (this.state.image && this.state.image.file) {
        imageResult = await FormImageField.upload(`/api/banners/${this.props.bannerId}/presign`, this.state.image);
      }
      if (this.state.product_image && this.state.product_image.file) {
        productImageResult = await FormImageField.upload(`/api/banners/${this.props.bannerId}/presign`, this.state.product_image);
      }

      await Ajax.send(`/api/banners/${this.props.bannerId}?hash=${this.props.hash}`, {
        method: 'PATCH',
        data: {
          banner: {
            name: this.state.name,
            description: this.state.description,
            image: this.__toImage(imageResult, this.state.image),
            product_image: this.__toImage(productImageResult, this.state.product_image),
            tags: this.state.tags,
            custom_tags: this.state.custom_tags || [],
            links: this.state.links,
          }
        }
      })

      alert(I18n.t("banners.form.saved"));
      location.reload();
    } catch(err) {
      alert(I18n.t("banners.form.save_failed"));
      console.error(err);
      this.setState({
        loading: false,
        error: false,
      })
    }
  }

  __toImage(upload, state) {
    if (!upload) {
      if (state) {
        return {
          identifier: state.identifier,
          crop_x: state.crop_x,
          crop_y: state.crop_y,
          crop_width: state.crop_width,
          crop_height: state.crop_height,
        }
      }
      return undefined;
    }

    return {
      identifier: upload.identifier,
      crop_x: state.crop.x,
      crop_y: state.crop.y,
      crop_width: state.crop.width,
      crop_height: state.crop.height,
    }
  }

  removeTag(tag) {
    const i = (this.state.custom_tags || []).indexOf(tag);
    if (i === -1) { return; }

    const custom_tags = this.state.custom_tags.slice(0, i).concat(this.state.custom_tags.slice(i+1))
    this.setState({custom_tags});
  }

  addTag(tag) {
    const custom_tags = (this.state.custom_tags || []).concat([tag]).slice(0, 50);;
    this.setState({custom_tags});
    this.state.custom_tags = custom_tags;
  }

  render() {
    if (this.state.error) {
      return <div>{I18n.t("banners.form.save_failed")}</div>
    }
    if (this.state.loading) {
      return <div>
        <LoadingIndicator />
      </div>
    }

    return (
      <div className="BannerEditor">
        <FormTextField
          title={I18n.t("banners.form.name")}
          value={this.state.name}
          onChange={(name) => this.setState({name})}
          isValid={this.state.name.trim().length > 0}
          required={true}
        />
        <FormTextareaField
          title={I18n.t("banners.form.description")}
          value={this.state.description}
          onChange={(description) => this.setState({description})}
          isValid={this.state.description.trim().length > 0}
          required={true}
        />
        <FormImageField
          id="image"
          title={I18n.t("banners.form.image")}
          value={this.state.image}
          minWidth={600}
          maxWidth={2100}
          maxSize={2*1024*1024}
          minHeight={400}
          maxHeight={1400}
          minAspect={1500/1000}
          maxAspect={1500/1000}
          onChange={(file) => this.setState({image: file})}
        >
          <div className="print-format-info-box">
            <p><strong>{I18n.t("banners.form.file_info")}</strong></p>
            <ul>
              <li><strong>{I18n.t("banners.form.min_res")}</strong> 600x400 Pixel</li>
              <li><strong>{I18n.t("banners.form.max_res")}</strong> 2100x1400 Pixel</li>
              <li><strong>{I18n.t("banners.form.max_size")}</strong> Maximal 2 MB</li>
              <li><strong>{I18n.t("banners.form.file_format")}</strong> JPG, PNG</li>
              <li><strong>{I18n.t("banners.form.aspect")}</strong> 3:2 (z.B. 1500x1000 Pixel)</li>
              <li><strong>{I18n.t("banners.form.must_be_sfw")}</strong></li>
              <li><a href="https://www.dokomi.de/files/exhibitor/BannerTemplate.png" target="_blank">{I18n.t("banners.form.example_template")}</a></li>
            </ul>
          </div>
        </FormImageField>
        <FormImageField
          id="product_image"
          title={I18n.t("banners.form.product_image")}
          value={this.state.product_image}
          minAspect={0}
          maxAspect={0}
          maxSize={2*1024*1024}
          minWidth={400}
          minHeight={400}
          maxWidth={2000}
          maxHeight={2000}
          onChange={(file) => this.setState({product_image: file})}
        >
          <div className="print-format-info-box">
            <p><strong>{I18n.t("banners.form.file_info")}</strong></p>
            <ul>
              <li><strong>{I18n.t("banners.form.min_res")}</strong> 400x400 Pixel</li>
              <li><strong>{I18n.t("banners.form.max_res")}</strong> 2000x2000 Pixel</li>
              <li><strong>{I18n.t("banners.form.max_size")}</strong> Maximal 2 MB</li>
              <li><strong>{I18n.t("banners.form.recommend_aspect")}</strong> 2:3 (z.B. 1000x1500 Pixel)</li>
              <li><strong>{I18n.t("banners.form.file_format")}</strong> JPG, PNG</li>
              <li><strong>{I18n.t("banners.form.must_be_sfw")}</strong></li>
              <li><a href="https://www.dokomi.de/files/exhibitor/ProductImageTemplate.png" target="_blank">{I18n.t("banners.form.example_template")}</a></li>
            </ul>
          </div>
        </FormImageField>
        <FormUrlListField
          title={I18n.t("banners.form.websites")}
          value={this.state.links}
          onChange={(links) => this.setState({links})}
        />
        <FormTagSelector
          tags={this.state.all_tags || []}
          value={this.state.tags || []}
          onChange={(tags) => this.setState({tags})}
        />
        <FormCustomTagEditor
          title={I18n.t("banners.form.custom_tags")}
          tags={this.state.custom_tags || []}
          onTagAdded={(tag) => this.addTag(tag)}
          onTagRemoved={(tag) => this.removeTag(tag)}
          disabled={(this.state.custom_tags || []).length >= 50}
        />
        <button className="btn btn-primary" onClick={()=>this.submit()}>{I18n.t("banners.form.save")}</button>
        <br/>
        <br/>
        <span className="required">* Pflichtfeld</span>
      </div>
    )
  }
}

export default BannerEditor;