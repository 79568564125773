import React, {Component} from "react";

export default class MissingUpload extends Component {
  render() {
    const {upload} = this.props;

    return (
      <li>
        <a href={this.props.gotoPrintMediaEditorPath(upload.id)}>
          {upload.name}
        </a>
      </li>
    )
  }
}