import React, {Component} from "react";
import MissingUploadLost from "../upload/MissingUploadList";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    this.reload();
  }

  async reload() {
    this.setState({loading: true});

    this.setState({
      loading: false,
      //booths: await (await fetch('/api/booths.json')).json(),
      uploads: await (await fetch('/api/uploads.json')).json(),
    });
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <div className="dashboard">
        <MissingUploadLost
          uploads={this.state.uploads.filter(upload => upload.status !== 'succeeded')}
          gotoPrintMediaEditorPath={this.props.gotoPrintMediaEditorPath}
        />
      </div>
    )
  }
}