import React from "react"
import PropTypes from "prop-types"
import I18n from "i18n-js";

class ErrorIndicator extends React.Component {
  render() {
    return (
      <div className="text-danger">{I18n.t('common.error_message')}</div>
    )
  }
}

export default ErrorIndicator
