import React, { createRef } from "react"

class UiImageCropSelectComponent extends React.Component {
  constructor(props) {
    super(props);

    this.container = createRef();

    this.state = {
      currentHandle: -1,
      aspect: this.props.width / Math.max(1, this.props.height),
      box: this.maxBoxAt({x: 0.5, y: 0.5})
    }

    this.props.onCrop(this.state.box);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.width !== this.props.width || oldProps.height !== this.props.height) {
      this.setState({
        aspect: this.props.width / Math.max(1, this.props.height),
      })
    }
  }

  maxBoxAt(t) {
    let use = this.props.minAspect;
    if (use === 0) { use = this.props.maxAspect; }
    if (use === 0) { return {x:0,y:0,w:1,h:1}}

    const aspect = this.props.width / Math.max(1, this.props.height);
    let w = 1;
    let h = w / use * aspect;
    if (h > 1) {
      h = 1;
      w = h * use / aspect;
    }
    let x = t.x - w * 0.5;
    let y = t.y - h * 0.5;
    if (x < 0) { x = 0; }
    if (x+w > 1) { x=1-w; }
    if (y < 0) { y = 0; }
    if (y+h > 1) { y=1-h; }
    return {x,y,w,h};
  }

  onResetMouseDown=(e) => {
    e.stopPropagation();
    e.preventDefault();

    const p = this.translate(e);
    this.setBox(this.maxBoxAt(p));
  }

  onBgMouseDown=(e) => {
    e.stopPropagation();
    e.preventDefault();

    const p = this.translate(e);
    this.setState({currentHandle: -1, rel: {
      x: this.state.box.x - p.x,
      y: this.state.box.y - p.y,
    }});

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onStop);
  }

  onHandleMouseDown=(index, e) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({currentHandle: index})

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onStop);
  }

  onMouseMove=(e) => {
    e.stopPropagation();
    e.preventDefault();

    const target = this.translate(e);
    if (this.state.currentHandle === -1) {
      this.moveAll(target)
    } else {
      this.moveHandle(this.state.currentHandle, target)
    }
  }

  moveAll({x, y}) {
    const w = this.state.box.w;
    const h = this.state.box.h;

    x = Math.max(0, x + this.state.rel.x);
    y = Math.max(0, y + this.state.rel.y);
    x = Math.min(x, 1 - w);
    y = Math.min(y, 1 - h);

    this.setBox({x, y, w, h})
  }

  moveHandle(index, t) {
    switch (index) {
      case 1: this.resizeTop(t); break;
      case 4: this.resizeLeft(t); break;
      case 6: this.resizeRight(t); break;
      case 9: this.resizeBottom(t); break;
    }
  }

  resizeTop({x, y}) {
    const newTop = Math.max(0, y);
    const newBottom = this.state.box.y + this.state.box.h;
    this.resizeByHeight(newTop, newBottom, 0.5);
  }

  resizeBottom({x, y}) {
    const newTop = this.state.box.y;
    const newBottom = y;
    this.resizeByHeight(newTop, newBottom, 0.5);
  }

  resizeLeft({x, y}) {
    const newLeft = Math.max(x, 0);
    const newRight = this.state.box.x + this.state.box.w;
    this.resizeByWidth(newLeft, newRight, 0.5);
  }

  resizeRight({x, y}) {
    const newLeft = this.state.box.x;
    const newRight = x;
    this.resizeByWidth(newLeft, newRight, 0.5);
  }

  resizeByHeight(top, bottom, l) {
    if (top > bottom) { const y=top; top=bottom; bottom=y; }
    
    const h = bottom - top;
    if (h === 0) { return; }

    let aspect = this.state.box.w / h * this.state.aspect;
    aspect = Math.max(this.props.minAspect, aspect);
    if (this.props.maxAspect > 0) {
      aspect = Math.min(this.props.maxAspect, aspect);
    }

    let w = h * aspect / this.state.aspect;
    let wdiff = w - this.state.box.w;
    this.setBox({
      x: this.state.box.x - l * wdiff,
      w: w,
      y: top,
      h: h
    });
  }

  resizeByWidth(left, right, l) {
    if (left > right) { const y=left; left=right; right=y; }
    
    const w = right - left;
    if (w === 0) { return; }

    let aspect = w / this.state.box.h * this.state.aspect;
    aspect = Math.max(this.props.minAspect, aspect);
    if (this.props.maxAspect > 0) {
      aspect = Math.min(this.props.maxAspect, aspect);
    }

    let h = w / aspect * this.state.aspect;
    let hdiff = h - this.state.box.h;
    this.setBox({
      x: left,
      w: w,
      y: this.state.box.y - l * hdiff,
      h: h
    });
  }

  setBox({x,y,w,h}) {
    if (x < 0) { x = 0; }
    if (x >= 1) { x = 1; }
    if (y < 0) { y = 0; }
    if (y >= 1) { y = 1; }
    if (x+w >= 1) { x=1-w;}
    if (y+h >= 1) { y=1-h;}
    if (x < 0) { w=1; x=0; }
    if (y < 0) { h=1; y=0; }

    if (h === 0) { return; }

    let aspect = w/h*this.state.aspect;
    if (aspect < this.props.minAspect) {
      h = w / this.props.minAspect * this.state.aspect;
    } else if (aspect > this.props.maxAspect && this.props.maxAspect > 0) {
      w = h * this.props.maxAspect / this.state.aspect;
    }
    
    const box = {x,y,w,h};

    this.setState({box});
    this.props.onCrop(box)
  }

  onStop=(e) => {
    e.stopPropagation();
    e.preventDefault();

    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onStop);
  }

  translate(e) {
    if (!this.container.current) { return {x:0, y:0}}

    const rect = this.container.current.getBoundingClientRect();
    if (rect.width === 0 || rect.height === 0) { return {x:0, y:0}}
    
    const x = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
    const y = Math.max(0, Math.min(1, (e.clientY - rect.top) / rect.height));
    return {x, y}
  }

  render() {
    const rpos = this.state.box;
    const cont = {w: this.props.width, h: this.props.height};
    const pos = {x: rpos.x*cont.w, y: rpos.y*cont.h, w: rpos.w*cont.w, h: rpos.h*cont.h};

    return (
      <div className="ui-crop-reset-box" ref={this.container} onMouseDown={this.onResetMouseDown}>
        <div className="ui-crop-dark" onMouseDown={this.onResetMouseDown} style={{left:0, top:0, width: pos.x, height:cont.h}} />
        <div className="ui-crop-dark" onMouseDown={this.onResetMouseDown} style={{left:pos.x+pos.w, top:0, width: cont.w-pos.w-pos.x, height:cont.h}} />
        <div className="ui-crop-dark" onMouseDown={this.onResetMouseDown} style={{left:pos.x, top:0, width: pos.w, height:pos.y}} />
        <div className="ui-crop-dark" onMouseDown={this.onResetMouseDown} style={{left:pos.x, top:pos.y+pos.h, width: pos.w, height:cont.h-pos.h-pos.y}} />

        <div className="ui-crop-bg" onMouseDown={this.onBgMouseDown} style={{
          left: pos.x,
          top: pos.y,
          width: pos.w,
          height: pos.h
        }}>
          <div className="ui-crop-handle ui-crop-handle-top ui-crop-handle-mid" onMouseDown={(e) => this.onHandleMouseDown(1, e)}></div>
          <div className="ui-crop-handle ui-crop-handle-center ui-crop-handle-left" onMouseDown={(e) => this.onHandleMouseDown(4, e)}></div>
          <div className="ui-crop-handle ui-crop-handle-center ui-crop-handle-right" onMouseDown={(e) => this.onHandleMouseDown(6, e)}></div>
          <div className="ui-crop-handle ui-crop-handle-bottom ui-crop-handle-mid" onMouseDown={(e) => this.onHandleMouseDown(9, e)}></div>
        </div>
      </div>
    )
  }
}

export default class UiImageCropComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
    this.container = createRef();
  }

  onResize=() => {
    if (!this.container.current) { return; }

    const canvas = document.createElement('canvas');
    canvas.width = this.props.image.width;
    canvas.height = this.props.image.height; 
    const context = canvas.getContext('2d');
    context.drawImage(this.props.image, 0, 0);
    this.onResizeWithImage(canvas.toDataURL('image/png'));
  }

  onResizeWithImage(imageData) {
    const c = this.container.current;
    const cw = c.clientWidth;
    const ch = c.clientHeight;
    const i = this.props.image;
    const iw = i.width;
    const ih = i.height;

    if (iw === 0 || ih === 0) { return; }

    const fw = cw / iw;
    const fh = ch / ih;
    const f = Math.min(fw, fh);

    this.setState({
      containerWidth: cw,
      containerHeight: cw,
      imageData: imageData,
      scaleFactor: f,
      scaledWidth: Math.round(iw * f),
      scaledHeight: Math.round(ih * f)
    })
  }

  componentDidUpdate(oldProps) {
    if (oldProps.image !== this.props.image) {
      this.onResize();
    }
  }

  componentDidMount() {
    this.onResize();

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onCrop=(a) => {
    this.props.onCrop(a)
  }

  render() {
    return (
      <div className="ui-crop-component-container" ref={this.container}>
        {this.state.containerWidth === undefined ? null :
          <div className="ui-crop-component-image" style={{
            width: this.state.scaledWidth,
            height: this.state.scaledHeight,
            marginLeft: -this.state.scaledWidth*0.5,
            marginTop: -this.state.scaledHeight*0.5
          }}>
            <img src={this.state.imageData} alt="Preview" />
            <UiImageCropSelectComponent
              width={this.state.scaledWidth}
              height={this.state.scaledHeight}
              minAspect={this.props.minAspect}
              maxAspect={this.props.maxAspect}
              onCrop={this.onCrop}
            />
          </div>
        }
      </div>
    )
  }
}