class Ajax {
  authenticityToken() {
    var metas = document.getElementsByTagName('meta');

    for (var i=0; i<metas.length; i++) {
      if (metas[i].getAttribute("name") !== "csrf-token") {
        continue;
      }

      return metas[i].getAttribute("content");
    }

    throw new Error('Unable to find CSRF token');
  }

  async send(action, options={}) {
    const result = await fetch(action, {
      method: options.method || 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.authenticityToken()
      },
      body: options.data ? JSON.stringify(options.data) : null,
      credentials: 'same-origin'
    })

    if (result.status !== 200) {
      throw new Error(`Ajax resonded with invalid result code: ${result.status}`)
    }

    return await result.json();
  }
}

export default new Ajax();
