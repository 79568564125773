import React from "react"
import I18n from "i18n-js";

class BoothPriceTableEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      rows: (props.value || '').split(/\n/g).filter((r) => r.trim().length > 0).map((r) => r.split(/\t/g)).filter((r) => r.length === 2).concat([['', '']])
    }
  }

  updateRow(row, col, text) {
    const rows = this.state.rows.map((r, i) => i !== row ? r : [
      col === 0 ? text : r[0],
      col === 1 ? text : r[1]
    ])
    if (rows.length === row + 1) {
      rows.push(['', '']);
    }

    this.setNewRows(rows);
  }

  setNewRows(rows) {
    const value = rows.map((r) => r[0].replace(/\t/g, ' ') + '\t' + r[1].replace(/\t/g, ' ')).filter((r) => r.trim().length > 0).join('\n');

    this.setState({
      rows,
      value 
    })
  }

  remove(row) {
    this.setNewRows(this.state.rows.filter((r, i) => i !== row));
  }

  render() {
    return (
      <div className="boothpricetable">
        <textarea name="booth[catering_scope_of_services]" value={this.state.value} style={{display: 'none'}} />
        <div className="boothpricetablerow">
          <div className="boothpricetablename">
            {I18n.t('unconfirmed_booths.form.table_item_name')}
          </div>
          <div className="boothpricetableprice">
            {I18n.t('unconfirmed_booths.form.table_item_price')}
          </div>
          <div className="boothpricetablebtn">
          </div>
        </div>
        {this.state.rows.map((r,i) => 
          <div className="boothpricetablerow" key={i}>
            <div className="boothpricetablename">
              <input
                type="text"
                className="form-input"
                value={r[0]}
                onChange={(e) => this.updateRow(i, 0, e.target.value)}
                placeholder={I18n.t('unconfirmed_booths.form.table_item_name')}
              />
            </div>
            <div className="boothpricetableprice">
              <input
                type="text"
                className="form-input"
                value={r[1]}
                onChange={(e) => this.updateRow(i, 1, e.target.value)}
                placeholder={I18n.t('unconfirmed_booths.form.table_item_price')}
              />
            </div>
            <div className="boothpricetablebtn">
              {(i+1) < this.state.rows.length ? <a href="#" className="btn" onClick={(e) => {
                console.log('click');
                e.stopPropagation();
                e.preventDefault();
                this.remove(i)
              }} tabIndex="-1">X</a> : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BoothPriceTableEditor
