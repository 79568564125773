import React from "react"
import Status from "../common/Status"
import PropTypes from "prop-types"
import I18n from "i18n-js";

class Booth extends React.Component {
  render() {
    const {booth} = this.props;

    return (
      <a href={booth.path}>
        <h4>{booth.name} <Status status={booth.status} /></h4>
        <span>{booth.booth_type.name}, {booth.booth_type_size.text}</span><br/>
      </a>
    );
  }
}

export default Booth
