import React from "react"
import PropTypes from "prop-types"
import I18n from "i18n-js";

class Group extends React.Component {
  renderListOptions() {
    return (
      <ul className="booking-options">
        {this.props.group.members.map(member => 
          <li key={member.type.identifier}>
            <a href={member.path}>
              <h4>{member.type.name}</h4>
              <span dangerouslySetInnerHTML={{__html: member.type.description}}/>
            </a>
          </li>
        )}
      </ul>
    )
  }

  renderCompareValue(value) {
    if (value === true) {
      return <span className="icon-ok"></span>
    } else if (value === false) {
      return <span className="">-</span>
    } else {
      return value;
    }
  }

  renderHeadline(member) {
    if (!member.type.available) {
      return <strong>{member.type.name}</strong>
    }

    return (
      <div>
        <img src={member.type.image} alt={member.type.name} />
        <a href={member.path}>{member.type.name}</a>
      </div>
    );
  }

  renderBookButton(member) {
    if (!member.type.available) {
      return <small className="not-available">{I18n.t('dashboard.index.sold_out')}</small>
    }

    return <a href={member.path} className="btn">{I18n.t('dashboard.index.book_now')}</a>;
  }

  renderCompareOptions() {
    var groupHeaders = [];
    let groupHeaderKeys = {};
    for (let i=0; i<this.props.group.members.length; i++) {
      var member = this.props.group.members[i];
      var groupName = '';
      if (member.type.features.hasOwnProperty('group')) {
        groupName = member.type.features.group;
      }
      if (!groupHeaderKeys[groupName]) {
        var parts = groupName.split(/:/);
        if (parts.length === 1) {
          groupHeaderKeys[groupName] = {sort: 10000, name: groupName, members: []}
        } else {
          groupHeaderKeys[groupName] = {sort: parseInt(parts[0], 10), name: parts[1], members: []}
        }
        groupHeaders.push(groupHeaderKeys[groupName]);
      }
      groupHeaderKeys[groupName].members.push(member);
    }

    groupHeaders.sort((a, b) => a.sort - b.sort);

    return (
      <div>
        {groupHeaders.map((g) => <div key={g.name}>
          {g.name ? <div><h4>{g.name}</h4></div> : <span></span>}
          {this.renderGroups(g.members)}
        </div>)}
      </div>
    )
  }

  renderGroups(members) {
    var parts = [];
    members.sort((a, b) => a.type.name < b.type.name ? -1 : 1);
    for (let i = 0; i < members.length; i += 2) {
        parts.push(members.slice(i, i + 2));
    }

    return (
      <div className="booking-options-new">
        {parts.map((items, index) => 
          <div key={index} className="booking-options-row">
            {items.map((member) => 
              <div key={member.type.identifier} className="booking-options-card">
                <a className="booking-option-header" href={member.path}>
                  <h4>{member.type.name}</h4>
                </a>
                <span className="booking-option-image">
                  <a href={member.path}>
                    <img src={member.type.image} alt={member.type.name} />
                  </a>
                </span>
                <span className="booking-option-info">
                  <span dangerouslySetInnerHTML={{__html: member.type.description}}/>

                  <a className="btn" href={member.path}>{I18n.t('dashboard.index.book_now')}</a>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  renderOptions() {
    const {group} = this.props;
    
    if (group.features.length) {
      return this.renderCompareOptions();
    } else {
      return this.renderListOptions();
    }
  }

  render () {
    const {group} = this.props;

    return (
      <div>
        <div dangerouslySetInnerHTML={{__html: group.introduction}} />
        <hr/>
        <h5>{I18n.t('dashboard.index.select_booth_type')}</h5>
        {this.renderOptions()}
      </div>
    );
  }
}

export default Group
