import React from "react"
import LoadingIndicator from '../../common/LoadingIndicator';
import Ajax from '../../common/Ajax';

export default class Postpone extends React.Component {
  constructor(props) { 
    super(props);

    this.state = {
      postponed: this.props.postponed,
      loading: false
    }
  }

  setPostponed=async (e) => {
    e.preventDefault();
    this.update(true);
  }

  update=async(postponed) => {
    this.setState({loading: true});

    const result = await Ajax.send(`/en/manage/events/${this.props.eventId}/bookings/${this.props.bookingId}/set_postponed`, {
      method: 'POST',
      data: {postponed}
    });

    this.setState({
      postponed: result.postponed,
      loading: false
    });
  }

  unsetPostponed=async (e) => {
    e.preventDefault();
    this.update(false);
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />
    } else if (this.state.postponed) {
      return <button className="postpone postpone-true" onClick={this.unsetPostponed}>2022</button>
    } else {
      return <button className="postpone postpone-false" onClick={this.setPostponed}>2021</button>
    }
  }
}