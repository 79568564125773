import React from "react"
import PropTypes from "prop-types"
import I18n from "i18n-js";
import LoadingIndicator from "../common/LoadingIndicator";
import ErrorIndicator from "../common/ErrorIndicator";
import Booth from "./Booth";

class BoothList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.reload();
  }

  async reload() {
    this.setState({loaded: false, error: null});
    try {
      this.setState({
        loaded: true,
        booths: await (await fetch('/api/booths.json')).json()
      });
    } catch(err) {
      this.setState({
        loaded: true,
        error: err
      })
    }
  }

  render() {
    if (!this.state.loaded) { return <LoadingIndicator />; }
    else if (this.state.error) { return <ErrorIndicator error={this.state.error} />; }

    if (!this.state.booths.length) {
      return <div>{I18n.t('booths.index.empty')}</div>;
    }

    return (
      <ul className="booth-list booking-options">
        {this.state.booths.map(booth => {
          booth.path = this.props.link.replace(/\$ID\$/g, booth.id);
          return (<li key={booth.id}>
            <Booth booth={booth} />
          </li>)
        })}
      </ul>
    )
  }
}

export default BoothList
