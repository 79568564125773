import React, {Component} from "react";
import I18n from "i18n-js";

function testIsValid(value) {
  return value.match(/^\s*https?:\/\/.+/i) !== null || value === '';
}

export class StaticUrlField extends Component {
  render() {
    const anyInvalid = this.props.links.some((a) => !testIsValid(a));

    return (
      <React.Fragment>
        <ul>
          {this.props.links.map((value, key) =>
            <li key={key} className={!testIsValid(value) ? "field_with_errors" : ""}><input disabled={this.props.disabled} type="text" className={'form-input' + (testIsValid(value) ? '' : ' text-danger')} value={value} placeholder="https://www.dokomi.de/" onChange={(e) => {
              this.props.onChangeIndex(key, e.target.value);
            }} /></li>
          )}
        </ul>
        {anyInvalid ? <span className="form-input-hint is-danger">{I18n.t("common.forms.invalid_url")}</span> : null}
      </React.Fragment>
    )
  }  
}

export default class UrlField extends Component {
  constructor(props) {
    super(props);

    var isSendLater = props.content === 'E-Mail'

    this.state = {
      values: isSendLater ? [''] : (props.content || '').split(/\n/g).filter(function(row) { return row.trim().length > 0; }).concat(['']),
      isSendLater: isSendLater
    }

    this.state.isValid = this.state.values.map((value) => testIsValid(value))
    this.state.content = this.generateContent(this.state.isSendLater, this.state.values, this.state.isValid);
  }

  generateContent(sendLater, values, isValid) {
    if (sendLater) { return 'E-Mail'; }
    return values.filter((value, key) => isValid[key] && value.trim() !== '').map((row) => row.trim()).join('\n');
  }

  setSendLater(isSendLater) {
    this.setState({
      isSendLater: isSendLater,
      content: this.generateContent(isSendLater, this.state.values, this.state.isValid)
    })
  }
  
  setValue(index, value) {
    var newValues = this.state.values.slice();
    var newIsValid = this.state.isValid.slice()
    newValues[index] = value;
    newIsValid[index] = testIsValid(value);
    if (!newValues.some(function(value) { return value.trim().length === 0; })) {
      newValues.push('');
      newIsValid.push(true)
    }

    this.setState({
      values: newValues,
      isValid: newIsValid,
      content: this.generateContent(this.state.isSendLater, newValues, newIsValid)
    })
  }

  render() {
    return (
      <React.Fragment>
        <textarea style={{display: 'none'}} name={this.props.name} value={this.state.content} readOnly />
        <StaticUrlField
          links={this.state.values}
          disabled={this.state.isSendLater}
          onChangeIndex={(k, v) => this.setValue(k, v)}
        />
        {this.props.sendLaterLabel ? <label><input type="checkbox" checked={this.state.isSendLater} onChange={(e) => this.setSendLater(e.target.checked)} /> {this.props.sendLaterLabel}</label> : null}
      </React.Fragment>
    )
  }
}