import React, {Component} from "react";
import MissingUpload from "./MissingUpload";
import I18n from "i18n-js";

export default class MissingUploadList extends Component {

  render() {
    const {uploads, ...other} = this.props;

    if (!uploads.length) {
      return null;
    }

    return (
      <React.Fragment>
        <hr/>
        <div className="missing-uploads">
          <div>
            <strong>{I18n.t('dashboard.index.missing_print_media')}</strong>

            <ul>
              {uploads.map(upload =>
                <MissingUpload upload={upload} key={upload.id} {...other} />
              )}
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }
}