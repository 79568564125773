import React from "react"
import BoothShapePreview from './BoothShapePreview';
import I18n from "i18n-js";

class BoothShapeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      x: parseInt(props.valueX || props.axisX[0]),
      y: parseInt(props.valueY || props.axisY[0]),
      position: props.valuePlacement ? props.placements.find(p => p[0] === props.valuePlacement)[1] : 0
    }

    this.state.positionId = props.placements.find(p => p[1] === this.state.position)[0];
  }

  componentDidMount() {
    this.updatePriceElements();
  }

  setAxisX=(e) => {
    this.setState({x: parseInt(e.target.value)});

    this.updatePriceElements();
  }

  setAxisY=(e) => {
    this.setState({y: parseInt(e.target.value)});

    this.updatePriceElements();
  }

  setActualPosition=(e) => {
    const option = this.state.position % 100;
    const position = parseInt(e.target.value) + option

    this.setState({
      position,
      positionId: this.props.placements.find(p => p[1] === position)[0]
    });

    this.updatePriceElements();
  }

  setOption=(e) => {
    const option = this.state.position % 100;
    const actualPosition = this.state.position - option;
    const position = parseInt(e.target.value) + actualPosition;

    this.setState({
      position,
      positionId: this.props.placements.find(p => p[1] === position)[0]
    });

    this.updatePriceElements();
  }

  updatePriceElements() {
    setImmediate(() => {
      const price = document.getElementById('boothShapeEditorBasePrice');
      const size  = document.getElementById('boothShapeEditorBaseSize');

      price.value = this.qmPrice().toString();
      size.value  = this.size().toString();

      this.fireChangeEvent(price);
      this.fireChangeEvent(size);
    })
  }

  fireChangeEvent(element) {
    if ("createEvent" in document) {
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      element.dispatchEvent(evt);
    } else {
      element.fireEvent("onchange");
    }
  }

  size() {
    return this.state.x * this.state.y;
  }

  isTooSmall() {
    return this.size() < this.props.minSize;
  }

  isTooLarge() {
    return this.size() > this.props.maxSize;
  }

  isWrongRatio() {
    const max = Math.max(this.state.x, this.state.y);
    const min = Math.min(this.state.x, this.state.y);

    return (min / max) < this.props.minRatio;
  }

  isValid() {
    return !this.isTooSmall()
      && !this.isTooLarge()
      && !this.isWrongRatio();
  }

  qmPrice() {
    return this.props.price * this.props.placements.find(p => p[0] === this.state.positionId)[2];
  }

  nettoPrice() {
    return this.size() * this.qmPrice();
  }

  displayPrice() {
    return this.nettoPrice();
  }

  beautifulPrice(number) {
    if (DKM.context.included_vat_prices) {
      number *= 1.19;
    }

    const totalCents = Math.round(number * 100);
    const euro = Math.floor(totalCents / 100);
    const cents = (totalCents % 100).toString();
    const centsPrefix = cents.length === 1 ? '0' : ''

    return `${euro},${centsPrefix}${cents}`;
  }

  renderError() {
    if (this.isTooSmall()) {
      return <div className="text-danger">{I18n.t('unconfirmed_booths.form.booth_too_small', {size: this.props.minSize})}</div>;
    } else if (this.isTooLarge()) {
      return <div className="text-danger">{I18n.t('unconfirmed_booths.form.booth_too_large', {size: this.props.maxSize})}</div>;
    } else if (this.isWrongRatio()) {
      return <div className="text-danger">{I18n.t('unconfirmed_booths.form.booth_invalid_ratio')}</div>;
    } else {
      return null;
    }
  }

  renderPrice() {
    return <div className="bse-price">
      <small>{I18n.t('unconfirmed_booths.form.booth_base_price')}: </small>
      <strong>{this.beautifulPrice(this.displayPrice())} €</strong>  
    </div>;
  }

  render() {
    const {x, y, position} = this.state;

    const option = position % 100;
    const actualPosition = position - option;

    return (
      <div className="boothshapeeditor">
        <div className="bse-field">
          <label>{I18n.t('unconfirmed_booths.form.booth_size')}</label>
          <span>
            <select value={x} className="bse-tiny" onChange={this.setAxisX}>
              {this.props.axisX.map(option => <option value={option} key={option}>{option}m</option>)}
            </select>
            <span className="bse-pad">×</span>
            <select value={y} className="bse-tiny" onChange={this.setAxisY}>
              {this.props.axisY.map(option => <option value={option} key={option}>{option}m</option>)}
            </select>
            <span className="bse-pad">=</span>
            <span>{x * y} m²</span>
          </span>
        </div>

        <div className="bse-field">
          <label>{I18n.t('unconfirmed_booths.form.booth_position')}</label>
          <span>
            <select value={actualPosition} onChange={this.setActualPosition}>
              <option value={0}>{I18n.t('unconfirmed_booths.form.booth_position_row')}</option>
              <option value={100}>{I18n.t('unconfirmed_booths.form.booth_position_corner')}</option>
              <option value={200}>{I18n.t('unconfirmed_booths.form.booth_position_head')}</option>
              <option value={400}>{I18n.t('unconfirmed_booths.form.booth_position_isle')}</option>
            </select>
          </span>
        </div>

        <div className="bse-field">
          <label>{I18n.t('unconfirmed_booths.form.booth_special')}</label>
          <span>
            <select value={option} onChange={this.setOption}>
              <option value={0}>{I18n.t('unconfirmed_booths.form.booth_special_none')}</option>
              <option value={1}>{I18n.t('unconfirmed_booths.form.booth_special_carpet')}</option>
            </select>
          </span>
        </div>

        <input type="hidden" name="booth[booth_type_placement_id]" value={this.state.positionId} />
        <input type="hidden" name="booth_size[width]" value={this.state.x} />
        <input type="hidden" name="booth_size[height]" value={this.state.y} />

        <BoothShapePreview width={x} height={y} position={actualPosition/100} option={option} />

        {!this.isValid() ? this.renderError() : this.renderPrice()}

      </div>
    );
  }
}

export default BoothShapeEditor
