import React from "react"
import PropTypes from "prop-types"
import I18n from "i18n-js";
import Dashboard from "./Dashboard";

class Welcome extends React.Component {
  renderGroups() {
    return this.props.groups.map(group => (
      <li key={group.id} className="primary">
        <a href={group.path}>
          <h4>{group.name}</h4>
          <span>{group.description}</span>
        </a>
      </li>
    ))
  }

  gotoPrintMediaEditorPath=(id) => {
    return this.props.edit_print_media_upload_path.replace(/__ID__/g, id);
  }

  renderDashboard() {
    return (
      <Dashboard
        gotoPrintMediaEditorPath={this.gotoPrintMediaEditorPath}
      />
    )
  }

  render() {
    const {name, email} = this.props;

    return (
      <div>
        <p>{I18n.t('dashboard.index.introduction_html', {name, email})}</p>

        {this.renderDashboard()}

        <hr/>

        <ul className="booking-options">
          {this.renderGroups()}
          <li>
            <a href={this.props.services_path}>
              <h4>{I18n.t('dashboard.index.extras_headline')}</h4>
              <span>{I18n.t('dashboard.index.extras_description')}</span>
            </a>
          </li>
          <li className="small small-first">
            <a href={this.props.marketing_path}>
              <h4>{I18n.t('dashboard.index.marketing_headline')}</h4>
              <span>{I18n.t('dashboard.index.marketing_description')}</span>
            </a>
          </li>
          <li className="small small-last">
            <a href={this.props.sponsoring_path}>
              <h4>{I18n.t('dashboard.index.sponsoring_headline')}</h4>
              <span>{I18n.t('dashboard.index.sponsoring_description')}</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Welcome
