import I18n from "i18n-js";
import React from "react"
import Ajax from "../common/Ajax";
import FormCropDialog from "./FormCropDialog";

export default class FormImageField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      cropRequest: null,
      preview: null
    }
  }

  fileChange(e) {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (!file.type.match(/^image\/(png|jpg|jpeg)$/)) {
      alert(I18n.t("banners.form.invalid_image_type"))
      return;
    }

    const createPreview = (preview) => {
      const imageInfo = document.createElement('img');
      imageInfo.onload = () => {
        if (imageInfo.width > this.props.maxWidth || imageInfo.width < this.props.minWidth
          || imageInfo.height > this.props.maxHeight || imageInfo.height < this.props.minHeight
        ) {
          alert(I18n.t("banners.form.invalid_resolution"));
          return;
        }
        if (imageInfo.size > this.props.maxSize) {
          alert(I18n.t("banners.form.invalid_size"))
          return;
        }

        this.setState({
          file,
          imageInfo,
          cropRequest: imageInfo,
        })
      }
      imageInfo.onerror = () => {
        console.error(`Error while loading image from`, preview);
        alert(I18n.t("banners.form.invalid_image"));
        return;
      }
      imageInfo.src = preview;
    }

    if (window.URL.createObjectURL) {
      createPreview(window.URL.createObjectURL(file));
    } else if (FileReader) {
      var reader = new FileReader();
      reader.onload = function() {
        createPreview(reader.result);
      }
      reader.readAsDataURL(file);
    }
  }

  cancelCrop() {
    this.setState({cropRequest: null})
  }

  commitCrop(c) {
    const crop = {
      x: Math.round(c.x * this.state.imageInfo.width),
      y: Math.round(c.y * this.state.imageInfo.height),
      width: Math.round(c.w * this.state.imageInfo.width),
      height: Math.round(c.h * this.state.imageInfo.height),
    }
    const preview = document.createElement('canvas');
    preview.width = crop.width;
    preview.height = crop.height;
    const context = preview.getContext('2d');
    context.drawImage(this.state.imageInfo, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);
    const previewUrl = preview.toDataURL('image/png');

    this.setState({
      cropRequest: null,
      preview: previewUrl
    })
    this.props.onChange({
      file: this.state.file,
      imageInfo: {
        width: this.state.imageInfo.width,
        height: this.state.imageInfo.height
      },
      preview: previewUrl,
      crop: crop
    })
  }
  
  getFileName() {
    if (this.state.file) {
      return this.state.file.name;
    }
    if (this.props.value && this.props.value.name) {
      return this.props.value.name;
    }
    return I18n.t("banners.form.no_image");
  }

  render() {
    return (
      <React.Fragment>
        {(this.state.cropRequest) ? <FormCropDialog
          image={this.state.cropRequest}
          minAspect={this.props.minAspect}
          maxAspect={this.props.maxAspect}
          onCancel={()=>this.cancelCrop()}
          onCommit={(c)=>this.commitCrop(c)}
        /> : null}
        <div className={`form-group ${this.props.isValid===false?'field_with_errors':''}`}>
          <label className="form-label"><strong>{this.props.title}</strong></label>
          {this.props.children}
          <div className="file-upload">
            <div className="file-upload-preview">
              <ul>
                <li>
                  <span className="info">
                    <span className="info-ok"></span>
                    <strong>{this.getFileName()}</strong><br/>
                    {
                      (this.props.value && this.props.value.preview_url) ? <img src={this.props.value.preview_url} alt="Vorschau" />
                      : this.state.preview ? <img src={this.state.preview} alt={I18n.t("banners.form.preview")} />
                      : null
                    }
                  </span>
                </li>
              </ul>
            </div>
            <input type="file" onChange={(e) => this.fileChange(e)} id={this.props.id} className="inputfile-react" />
            <label className="btn" htmlFor={this.props.id}>Upload</label>
          </div>
        </div>
      </React.Fragment>
    )
  }

  static async upload(presignUrl, file, options={}) {
    const progress = (v) => {
      if (options.onProgress) { options.onProgress(v); }
    }

    progress(0);
    
    return Promise(async (resolve, reject) => {
      try {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open('POST', presignUrl);
        xhr.setRequestHeader('X-CSRF-Token', Ajax.authenticityToken())

        var formData = new FormData();
        formData.append("file", file.file);

        function progress(event) {
          if (!event.lengthComputable) {
            return;
          }

          progress(event.loaded / event.total);
        }

        xhr.onprogress = progress;
        if(xhr.upload) {
          xhr.upload.onprogress = progress;
        }

        xhr.onreadystatechange = function() {
          if(xhr.readyState === 4){
            if(xhr.status === 200){
              resolve({
                identifier: xhr.response.identifier,
                size: file.file.size
              });
            } else {
              reject();
            }
          }
        };
        xhr.send(formData);
      } catch(error) {
        reject(error);
      }
    });
  }
}