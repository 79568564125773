import React from "react"
import PropTypes from "prop-types"
import I18n from "i18n-js";

class LoadingIndicator extends React.Component {
  render() {
    return (
      <div className="text-center"><span className="loading" /><br/>{I18n.t("common.loading")}</div>
    )
  }
}

export default LoadingIndicator
