import React from "react"
import LoadingIndicator from "../../common/LoadingIndicator";
import Ajax from '../../common/Ajax';

class AdminBoothTagEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: props.groups,
      loading: false
    };
  }

  onGroupChanged=(group) => {
    this.setState({groups: this.state.groups.slice()});
  }

  onRemoveGroup=(group) => {
    this.setState({groups: this.state.groups.filter(g => group.id !== g.id)});
  }

  createTagGroup=() => {
    this.setState({
      groups: this.state.groups.concat([{
        id: -(new Date()).getTime(),
        sort_index: 0,
        name: {de: '', en: '', ja: ''},
        tags: []
      }])
    })
  }

  save=async () => {
    this.setState({loading: true});

    try {
      const result = await Ajax.send(`/en/booth_tags`, {
        method: 'POST',
        data: {update: {groups: this.state.groups}}
      });
      window.location.reload();
    } catch(err) {
      console.error(err);
      alert('Es ist ein Fehler aufgetreten');
      this.setState({loading: false});
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />
    }

    return (
      <React.Fragment>
        <table border="1">
          <tbody>
            {this.state.groups.map(group => this.renderGroup(group, this.onGroupChanged, this.onRemoveGroup))}

            <tr>
              <td colSpan={8}><button onClick={this.createTagGroup}>Create group</button></td>
            </tr>
          </tbody>
        </table>
        <hr/>
        <button onClick={this.save}>Save changes</button>
      </React.Fragment>
    );
  }

  renderGroup(group, onChange, onRemoveGroup) {
    const setName = (e) => {
      if (e.target.name === 'sort_index') {
        group.sort_index = e.target.value;
      } else {
        group.name[e.target.name] = e.target.value;
      }
      onChange(group);
    }

    const createTag = (e) => {
      group.tags.push({
        id: -(new Date()).getTime(),
        sort_index: 0,
        name: {de: '', en: '', ja: ''},
        synonym_for_id: ''
      });
      onChange(group);
    }

    const onRemoveTag = (group, tag) => {
      group.tags = group.tags.filter(t => t.id !== tag.id);
      onChange(group);
    } 

    return (
      <React.Fragment key={group.id}>
        <tr>
          <th>{group.id < 0 ? 'NEW' : `#${group.id}`}</th>
          <th></th>
          <th><input type="text" placeholder="Sort" style={{width: 50}} value={group.sort_index} onChange={setName} name="sort_index" /></th>
          <th><input type="text" placeholder="Deutsch" value={group.name.de} onChange={setName} name="de" /></th>
          <th><input type="text" placeholder="English" value={group.name.en} onChange={setName} name="en" /></th>
          <th><input type="text" placeholder="Japanisch" value={group.name.ja} onChange={setName} name="ja" /></th>
          <th></th>
          <th></th>
          <th><button onClick={() => onRemoveGroup(group)}>Remove</button></th>
        </tr>
        {group.tags.map(tag => this.renderTag(group, tag, onChange, onRemoveTag))}
        <tr>
          <td></td>
          <td colSpan={5}><button onClick={createTag}>Add new tag</button></td>
        </tr>
      </React.Fragment>
    )
  }

  renderTag(group, tag, onChange, onRemoveTag) {
    const setName = (e) => {
      if (e.target.name === 'sort_index') {
        tag.sort_index = e.target.value;
      } else if (e.target.name === 'synonym_for_id') {
        tag.synonym_for_id = e.target.value;
      } else {
        tag.name[e.target.name] = e.target.value;
      }
      onChange(group, tag);
    }

    return (
      <tr key={tag.id}>
        <td></td>
        <td>{tag.id < 0 ? 'NEW' : `#${tag.id}`}</td>
        <td><input type="text" placeholder="Sort" style={{width: 50}} value={tag.sort_index} onChange={setName} name="sort_index" /></td>
        <td><input type="text" placeholder="Deutsch" value={tag.name.de} onChange={setName} name="de" /></td>
        <td><input type="text" placeholder="English" value={tag.name.en} onChange={setName} name="en" /></td>
        <td><input type="text" placeholder="Japanisch" value={tag.name.ja} onChange={setName} name="ja" /></td>
        <td><input type="text" placeholder="Synonym ID" style={{width: 100}} value={tag.synonym_for_id} onChange={setName} name="synonym_for_id" /></td>
        <td><input type="text" placeholder="Remote ID" style={{width: 100}} value={tag.remote_id} onChange={setName} name="remote_id" /></td>
        <th><button onClick={() => onRemoveTag(group, tag)}>Remove</button></th>
      </tr>
    )
  }
}

export default AdminBoothTagEditor;
